<template>
  <app-section id="faq">
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(item, index) in items"
        :key="index"
        eager
        :text="item.content"
        :title="item.title"
      />
    </v-expansion-panels>
  </app-section>
</template>

<script lang="ts" setup>
  const items = computed(() => [
    {
      title: 'Czy mogę umówić się na wizytę online?',
      content: 'Tak, umówisz się na wizytę online. Wystarczy, że wybierzesz odpowiednią usługę i zaznaczysz, że chcesz wizytę online.',
    },
    {
      title: 'Czy mogę umówić się na wizytę w weekend?',
      content: 'Tak, umówisz się na wizytę w weekend. Wystarczy, że wybierzesz odpowiednią usługę i zaznaczysz, że chcesz wizytę w weekend.',
    },
    {
      title: 'Czy mogę umówić się na wizytę w nocy?',
      content: 'Tak, umówisz się na wizytę w nocy. Wystarczy, że wybierzesz odpowiednią usługę i zaznaczysz, że chcesz wizytę w nocy.',
    },
    {
      title: 'Czy mogę umówić się na wizytę w nocy?',
      content: 'Tak, umówisz się na wizytę w nocy. Wystarczy, że wybierzesz odpowiednią usługę i zaznaczysz, że chcesz wizytę w nocy.',
    },
    {
      title: 'Czy mogę umówić się na wizytę w nocy?',
      content: 'Tak, umówisz się na wizytę w nocy. Wystarczy, że wybierzesz odpowiednią usługę i zaznaczysz, że chcesz wizytę w nocy.',
    },
    {
      title: 'Czy mogę umówić się na wizytę w nocy?',
      content: 'Tak, umówisz się na wizytę w nocy. Wystarczy, że wybierzesz odpowiednią usługę i zaznaczysz, że chcesz wizytę w nocy.',
    },
  ])
</script>
