<template>
  <pages-index-intro />
  <div class="position-relative overflow-hidden">
    <pages-index-parallax />
    <pages-index-story :style="{ position: 'relative', zIndex: 2 }" />
    <pages-index-blog :style="{ position: 'relative', zIndex: 2 }" />
    <pages-index-services :style="{ position: 'relative', zIndex: 2 }" />
    <pages-index-meet-us :style="{ position: 'relative', zIndex: 2 }" />
    <pages-index-sections-lines />
  </div>
  <pages-index-recommendations />
  <pages-index-quote />
  <pages-index-faq />
</template>
