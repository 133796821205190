import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1267.71 2498"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      stroke: "#f9f9f9",
      "stroke-linecap": "round",
      "stroke-width": "3",
      d: "M1249.04 1c-55.22 0-110.94 10.84-165.56 17.66-81.01 10.12-162.09 15.1-238.19 47.09-70.45 29.62-161.63 73.56-161.63 161.88 0 108.19 245.81 177.06 310.83 95.82 18.58-23.22-86.57-34.01-97.5-34.01-76.76 0-146.38 15.34-213.33 52.98-42.02 23.63-98.88 59.52-78.85 115.12 27.6 76.64 141.39 78.52 207.11 71.95 43.66-4.36 121.94-32.75 148.54 18.97 25.95 50.44-18.92 65.14-64.78 79.14-47.89 14.62-98.47 17.6-147.24 28.12-66.38 14.33-129.65 33.76-197.3 42.84-85.18 11.44-174.78 15.52-256.19 44.15-68.72 24.17-193.39 77.99-210.71 157.63-17.51 80.5 52.59 85.16 106.97 85.16 32 0 115.37-51.26 74-74-38.76-21.31-95.76 28.44-127.97 59.47C84.75 1021.56 24.66 1095.54 8.71 1164c-21.38 91.78 1.09 176.72 75.53 233.5 84.64 64.57 213.88 88.54 304.55 65 32.4-8.41 90.61-20.64 109.28-53.3 9.5-16.62 8.36-33.5-16.36-28.13-65.02 14.13-121.7 114.56-155.42 162.54-88.21 125.51-107.92 181.47-162.88 325.39-19.56 51.24-46.66 147.65 14.72 182.16 77.76 43.72 175.01-3.83 238.2-50.36 108.94-80.24 187.08-192.81 282.37-287.46 43.96-43.67 93.46-82.68 155.09-96.15 113.37-24.79 19.33 90.59-12.43 127.54-56.31 65.5-114.19 133.02-159.67 206.69-10.16 16.45-40 56.89-20.29 75.87 65.79 63.31 160.09-42.42 198.93-83.4 49.16-51.87 81.92-76.85 152.8-76.85 39.36 0 8.81 68.72-.99 88.3-61.05 122.04-154.26 238.31-133.49 383.61 12 83.93 107.81 138.95 184.86 154.36 89.36 17.86 140.02-32.09 203.19-84.71"
    }, null, -1)
  ])))
}
export default { render: render }