<template>
  <app-section class="position-relative">
    <tangled-lines-svg
      ref="lines"
      class="recommendations-tangled-lines"
      :style="{
        position: 'absolute',
        top: 0,
        left: '-10%',
        transform: 'translateY(-50%)',
        width: '110%',
      }"
    />
    <div class="d-flex justify-center">
      <v-btn-toggle
        v-model="type"
        class="mb-2 mb-md-8 overflow-visible"
        mandatory
        rounded="xl"
        selected-class="bg-black text-white"
        variant="outlined"
      >
        <v-btn
          class="font-weight-regular"
          color="black"
          :height="$vuetify.display.mdAndUp ? 50 : 36"
          :size="$vuetify.display.mdAndUp ? 'small' : 'x-small'"
          text="Psychiatra"
          value="psychiatra"
        />
        <v-btn
          class="font-weight-regular"
          color="black"
          :height="$vuetify.display.mdAndUp ? 50 : 36"
          :size="$vuetify.display.mdAndUp ? 'small' : 'x-small'"
          text="Psycholog"
          value="psycholog"
        />
      </v-btn-toggle>
    </div>

    <app-carousel
      :key="type"
      :autoplay="4000"
      :items="recommendations"
      :speed="900"
    >
      <template #item="{ item }">
        <app-recommendations-tile :recommendation="item" />
      </template>
    </app-carousel>
  </app-section>
</template>

<script lang="ts" setup>
  import { getRecommendations, Recommendation, RecommendationType } from '@/lib/recommendations'
  import TangledLinesSvg from '@/assets/lines/recommendations.svg?component'
  import { AnimeInstance } from 'animejs'

  const type = ref<RecommendationType>('psychiatra')

  const lines = ref(null)
  const linesIsVisible = useElementVisibility(lines)
  const animation = ref<AnimeInstance>()
  const recommendations = computed<Recommendation[]>(() => _shuffle(getRecommendations(type.value)))

  onMounted(() => {
    animation.value = anime({
      targets: '.recommendations-tangled-lines path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 8000,
      direction: 'alternate',
      loop: true,
    })
  })

  onBeforeUnmount(() => {
    animation.value?.pause()
  })

  watch(
    linesIsVisible,
    isVisible => isVisible ? animation.value?.play() : animation.value?.pause()
  )
</script>
