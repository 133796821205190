import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1363 597"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#687d96",
      d: "M46 467h9v9h-9zM0 113h9v9H0zm132 341h9v9h-9zm83-242h9v9h-9zm-85 356h9v9h-9zm1098 20h9v9h-9zm-867 0h9v9h-9zm402-464h9v9h-9zm404.4-50h9v9h-9zm110.6 59h9v9h-9zM262 143h9v9h-9zM60 228h9v9h-9zm1218 294h9v9h-9zm-59-64h9v9h-9zm-95-70h9v9h-9zM520 165h9v9h-9zM451.5 0h9v9h-9zM500 183h9v9h-9zm59.4-154h9v9h-9zM419 128h9v9h-9zm269 460h9v9h-9zm150-75h9v9h-9zm-453 59h9v9h-9zm161-105h9v9h-9zm808 30h9v9h-9zM509 71h9v9h-9zm102.4 21h9v9h-9zM757 44h9v9h-9zM164 265h9v9h-9zm808.2-53h9v9h-9zm47-69h9v9h-9zM949.4 33.5h9v9h-9zm-50.4 42h9v9h-9z"
    }, null, -1)
  ])))
}
export default { render: render }