<template>
  <app-section class="text-center position-relative">
    <h2 class="text-h1-secondary mb-4 mb-md-8">
      Chcemy <br class="d-block d-sm-none">
      towarzyszyć ludziom <br>
      w <em>zmianie</em> życia
    </h2>

    <app-btn
      append-icon="mdi-arrow-top-right"
      class="text-none"
      color="black"
      :height="38"
      size="small"
      text="Umów wizytę"
      :to="{ name: '/umow-wizyte' }"
    />
  </app-section>
</template>
